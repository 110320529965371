import { BrowserRouter, Switch } from 'react-router-dom';
import { AppAuthenticated, Authenticated, I18nContextProvider, user, SentryRoute as Route } from '@miotech/shared';
import 'normalize.css';
import Container from 'container';

import { ConfigProvider } from 'antd';
import { ThemeProvider } from '@miotech/prism';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

const appPath = `/gvi`;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function App() {
  return (
    <I18nContextProvider language={user.preference?.language || 'zh-CN'} resourcePath={`${appPath}/locales`}>
      <BrowserRouter basename={appPath}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#06C27F',
              borderRadius: 2,
            },
          }}
        >
          <ThemeProvider
            value={{
              system: 'light',
              branding: 'brandingEsghub',
            }}
          >
            <Switch>
              <Authenticated>
                {/* @ts-ignore */}
                <AppAuthenticated permission="GVI">
                  <Route path="/" component={() => <Route path="/" component={Container} />} />
                </AppAuthenticated>
              </Authenticated>
            </Switch>
          </ThemeProvider>
        </ConfigProvider>
      </BrowserRouter>
    </I18nContextProvider>
  );
}

export default App;
