import { resourceCDNHost } from 'constants/cdn';
import { css } from '@emotion/css';

export const primaryColor = '#00AE70';
export const darkGreen = '#00AE70';
export const lightGreen = '#0CD68D';
export const overlayGreen = '#5A7B6F';
export const themeBlack = '#111';
export const themeBGColor = '#F2F6F6';
export const rejectColor = '#ff6336';
export const titleColor = '#262A2F';
export const secondaryColor = '#9C9C9C';
export const highlightSecondaryColor = '#6cc655';
export const textGreenColor = '#01AF70';
export const textBlackColor = '#171e2d';
export const textGreyColor = '#6D6D6D';
export const lightGreenColor = '#9BC655';
export const deColor = '#DEDEDE';
export const efColor = '#efefef';

export const advancedColor = '#9BC655';
export const robustColor = '#FFD600';
export const limitedColor = '#FF9900';
export const weakColor = '#E44900';

export const branding = '#06C27F';
export const fourth = '#E2E5E7';

export const titleColorCss = css`
  color: ${titleColor};
`;

export const secondaryColorCss = css`
  color: ${secondaryColor};
`;

export const primaryColorCss = css`
  color: ${primaryColor};
`;

export const themeBlue = '#4A7DB5';
export const themeBorderGrey = '#ebebeb';
export const themeGreen = '#00b473';
export const backgroundLight = '#F9F9F9';
export const themeWhite = '#ffffff';
export const themeDefaultIconColor = '#8CA0B3';
export const themeLightGreen = '#17CA89';
export const themeWarningYellow = '#FFA800';
export const borderGrey = '#D8D8D8';
export const lightBgColor = '#fafafa';

export const environmentColor = '#94B433';
export const socialColor = '#1EB1E1';
export const governanceColor = '#FED004';
export const companyInfoColor = '#005270';

export const getColorCss = (color: string) =>
  css`
    color: ${color};
  `;

export const color666 = css`
  color: #666;
`;

export const colorWhite = css`
  color: #fff;
`;

export const backgroundWhite = css`
  background-color: ${themeWhite};
`;

export const fontW700 = css`
  font-weight: 700;
`;

export const fontW600 = css`
  font-weight: 600;
`;

export const fontW500 = css`
  font-weight: 500;
`;

export const fontW400 = css`
  font-weight: 400;
`;

export const width100 = css`
  width: 100%;
`;

export const widthAuto = css`
  width: auto;
`;

export const height100 = css`
  height: 100%;
`;

export const font10 = css`
  font-size: 10px;
`;

export const font12 = css`
  font-size: 12px;
`;

export const lineHeight12 = css`
  line-height: 12px;
`;

export const font14 = css`
  font-size: 14px;
`;

export const font16 = css`
  font-size: 16px;
`;

export const font18 = css`
  font-size: 18px;
`;

export const font20 = css`
  font-size: 20px;
`;

export const font22 = css`
  font-size: 22px;
`;

export const font24 = css`
  font-size: 24px;
`;

export const font26 = css`
  font-size: 26px;
`;

export const font28 = css`
  font-size: 28px;
`;

export const font32 = css`
  font-size: 32px;
`;

export const font36 = css`
  font-size: 36px;
`;

export const font40 = css`
  font-size: 40px;
`;

export const font42 = css`
  font-size: 42px;
`;

export const font64 = css`
  font-size: 64px;
`;

export const padding20 = css`
  padding: 20px;
`;

export const padding16 = css`
  padding: 16px;
`;

export const padding8 = css`
  padding: 8px;
`;

export const paddingBottom8 = css`
  padding-bottom: 8px;
`;

export const opacity05 = css`
  opacity: 0.5;
`;

export const opacity02 = css`
  opacity: 0.2;
`;

export const gap10 = css`
  gap: 10px;
`;

export const gap20 = css`
  gap: 20px;
`;

export const lineHeight1 = css`
  line-height: 1;
`;

export const lightHeight18 = css`
  line-height: 18px;
`;

export const cursorPointer = css`
  cursor: pointer;
`;

export const row = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const column = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const rowCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const columnCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const rowParted = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const rowMiddle = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

export const columnParted = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

export const expand = css`
  flex: 1;
  overflow: auto;
`;

export const displayFlex = css`
  display: flex;
`;

export const alignItemCenter = css`
  align-items: center;
`;

export const alignItemBottom = css`
  align-items: flex-end;
`;

export const alignItemStart = css`
  align-items: flex-start;
`;

export const justifyContentEnd = css`
  justify-content: flex-end;
`;

export const flex1 = css`
  flex: 1;
`;

export const flex2 = css`
  flex: 2;
`;

export const flex3 = css`
  flex: 3;
`;

export const pointer = css`
  cursor: pointer;
`;

export const absCenter = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const absLeftCenter = css`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const absRowCenter = css`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

export const absRightCenter = css`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const absRightTop = css`
  position: absolute;
  right: 0;
  top: 0;
`;

export const relative = css`
  position: relative;
`;

export const oneLineText = css`
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
`;

export const wordBreak = css`
  word-break: break-word;
  max-width: 200px;
`;

export const stylePageContainer = css`
  background: none;
  box-shadow: none;
  position: relative;
  overflow: auto;
`;

export const styleBackgroundImage = (img: string) => css`
  background-image: url(${resourceCDNHost}${img});
  background-size: cover;
`;

export const singleLineEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const styleEllipsisLine = (line = 5) => css`
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${line};
`;

export const flexWrap = css`
  display: flex;
  flex-wrap: wrap;
`;

export const hide = css`
  visibility: hidden;
`;

export const show = css`
  visibility: visible;
`;

export const marginTop32 = css`
  margin-top: 32px;
`;

export const styleEmptyCreateBtn = css`
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 400;
`;

export const pTop0Import = css`
  padding-top: 0 !important;
`;

export const bgTransparent = css`
  background: transparent;
`;

export const textCenter = css`
  text-align: center;
`;

export const overflowHidden = css`
  overflow: hidden;
`;

export const overflowAuto = css`
  overflow: auto;
`;

export const overflowUnset = css`
  overflow: unset;
`;

export const heightLight = css`
  .height-light {
    color: ${primaryColor};
  }
`;

export const noWrap = css`
  white-space: nowrap;
`;

export const borderRadius5 = css`
  border-radius: 5px;
`;

export const disableSelect = css`
  user-select: none;
`;

export const paddingTop4 = css`
  padding-top: 4px;
`;

export const paddingRight16 = css`
  padding-right: 16px;
`;

export const flexShrink0 = css`
  flex-shrink: 0;
`;

export const heightAuto = css`
  height: auto;
`;
