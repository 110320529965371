import { ROUTER_COMPANY_DETAIL_PREFIX } from 'constants/router';

import { FC, memo } from 'react';
import { css } from '@emotion/css';
import { HistoryOutlinedIcon } from '@miotech/prism';
import { rowCenter, styleEllipsisLine } from 'styles/template-style';
import { ISearchCompany } from 'services/global-search/type';
const RecentSearch: FC<{
  historyData: ISearchCompany[];
  clearSearchHistoryCompanyRun: () => void;
}> = ({ historyData = [], clearSearchHistoryCompanyRun }) => {
  const hasHistoryData = Boolean(historyData.length);

  return (
    <div className={recentSearchStyle}>
      <div className="recent-search">
        <span className={rowCenter}>
          <HistoryOutlinedIcon style={{ marginRight: '4px', fontSize: '12px' }} />
          最近搜索
        </span>
        {hasHistoryData && (
          <span
            className="clear"
            onClick={() => {
              clearSearchHistoryCompanyRun();
            }}
          >
            清除搜索历史
          </span>
        )}
      </div>

      {hasHistoryData ? (
        <div className="recent-search-list">
          {historyData.map(company => {
            return (
              <a
                target="_blank"
                key={company.uniScId}
                href={`/gvi${ROUTER_COMPANY_DETAIL_PREFIX}/${company.uniScId}`}
                rel="noreferrer"
                className="recent-search-company-name"
              >
                {company.entityName}
              </a>
            );
          })}
        </div>
      ) : (
        <div className="recent-no-data">暂无搜索历史</div>
      )}
    </div>
  );
};

export default memo(RecentSearch);

const recentSearchStyle = css`
  padding: 24px 16px;
  min-width: 200px;
  .recent-search {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .clear {
      cursor: pointer;
    }
  }
  .recent-no-data {
    color: #526079;
    margin-top: 8px;
    text-align: center;
  }

  .recent-search-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px 24px;
    margin-top: 8px;

    .recent-search-company-name {
      ${styleEllipsisLine(1)};
      color: #e7e7e7;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      text-decoration: none;
    }
  }
`;
