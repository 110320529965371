import { Models } from '@rematch/core';
import { notificationInfo } from './models/notificationInfo';
import { taskQuotaInfo } from './models/task-quota';
import { userInfo } from './models/user';
import { indicatorInfo } from './models/indicator';
import { ratingReportInfo } from './models/rating';
import { initializeConfig } from './models/initialize-config';
import { linkCheck } from './models/link-check';
import { portfolioInfo } from './models/portfolio-info';

import { quotaRemain } from './models/quota-remain';
import { greenLevel } from './models/green-level';

import { questionnaireInfo } from './models/questionnaire';

export interface RootModel extends Models<RootModel> {
  userInfo: typeof userInfo;
  notificationInfo: typeof notificationInfo;
  taskQuotaInfo: typeof taskQuotaInfo;
  indicatorInfo: typeof indicatorInfo;
  ratingReportInfo: typeof ratingReportInfo;
  initializeConfig: typeof initializeConfig;
  linkCheck: typeof linkCheck;
  portfolioInfo: typeof portfolioInfo;
  quotaRemain: typeof quotaRemain;
  greenLevel: typeof greenLevel;
  questionnaireInfo: typeof questionnaireInfo;
}

export const models: RootModel = {
  userInfo,
  notificationInfo,
  taskQuotaInfo,
  indicatorInfo,
  ratingReportInfo,
  initializeConfig,
  linkCheck,
  portfolioInfo,
  quotaRemain,
  greenLevel,
  questionnaireInfo
};
