import { GVI_ENDPOINT } from 'constants/endpoint';
import { connector, urlBuilder } from '../base';
import { IQuotaRemainItem, IMapStatisticItem, IMapStatisticListRequest, ISettingsInfo } from './home.service.type';
let build = urlBuilder(GVI_ENDPOINT);
/**
 * 数据地图列表接口
 * @param param
 * @returns
 */
export const getMapStatisticListAction = async ({ greenModel, areaCode }: IMapStatisticListRequest) => {
  return connector.post<IMapStatisticItem[]>({
    url: build('/map/statistic/list'),
    data: {
      greenModel,
      areaCode,
    },
  });
};
/**
 * 配额余量展示
 * @returns
 */
export const getQuotaRemainAction = async () => {
  return connector.get<IQuotaRemainItem[]>({
    url: build('/admin/quota/remain'),
  });
};

/**
 * 用户当前配置接口: 权限城市code 绿色等级name map
 * @returns
 */
export const getSettingsInfoAction = async () => {
  return connector.get<ISettingsInfo>({
    url: build('/user/settings/info'),
  });
};
