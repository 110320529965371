export enum EGreenLevel {
  DEEP_GREEN = 'DEEP_GREEN',
  MID_GREEN = 'MID_GREEN',
  LIGHT_GREEN = 'LIGHT_GREEN',
  MAYBE_GREEN = 'MAYBE_GREEN',
  NON_GREEN = 'NON_GREEN',
  // CAN_NOT_JUDGE = 'CAN_NOT_JUDGE',
}

export const greenLevelOrder = [
  EGreenLevel.DEEP_GREEN,
  EGreenLevel.MID_GREEN,
  EGreenLevel.LIGHT_GREEN,
  EGreenLevel.MAYBE_GREEN,
  EGreenLevel.NON_GREEN,
];

export const greenLevelTextColorMap = {
  [EGreenLevel.DEEP_GREEN]: '#059B66',
  [EGreenLevel.MID_GREEN]: '#389e0d',
  [EGreenLevel.LIGHT_GREEN]: '#7cb305',
  [EGreenLevel.MAYBE_GREEN]: '#08979c',
  [EGreenLevel.NON_GREEN]: '#959a9f',
  // [EGreenLevel.CAN_NOT_JUDGE]: '',
};
export enum EGreenModel {
  CBIRC = 'CBIRC', //银保监
  PBOC = 'PBOC', // 人民银行（人行)
  CGT = 'CGT', //《绿色低碳转型产业指导目录2024》标准
}

export interface IGreenStandardItem {
  greenModel: EGreenModel;
  text: string;
}

export const greenStandard: IGreenStandardItem[] = [
  {
    text: '《绿色低碳转型产业指导目录》（2024年）',
    greenModel: EGreenModel.CGT,
  },
  {
    text: '《绿色产业指导目录》（2019年）',
    greenModel: EGreenModel.PBOC,
  },
  {
    text: '《绿色融资统计制度》（2020年）',
    greenModel: EGreenModel.CBIRC,
  },
];

export const greenLevelBgColorMap: Record<EGreenLevel, string> = {
  [EGreenLevel.DEEP_GREEN]: '#e6fff2',
  [EGreenLevel.MID_GREEN]: '#f6ffed',
  [EGreenLevel.LIGHT_GREEN]: '#fcffe6',
  [EGreenLevel.MAYBE_GREEN]: '#e6fffb',
  [EGreenLevel.NON_GREEN]: '#f9f9f9',
};
