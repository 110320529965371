import { ROUTER_COMPANY_DETAIL_PREFIX } from 'constants/router';
import { resourceCDNHost } from 'constants/cdn';
import { FC, memo, useEffect } from 'react';
import { css } from '@emotion/css';
import { styleEllipsisLine } from 'styles/template-style';
import { useRequest } from 'ahooks';
import {
  addSearchHistoryCompany,
  clearSearchHistoryCompany,
  getSearchCompany,
  getSearchHistoryCompany,
} from 'services/global-search/global-search.service';
import NoData from 'components/no-data';
import RecentSearch from './recent-search';

const SearchCompany: FC<{
  searchValue: string;
  isFocus: boolean;
  setIsFocus?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isFocus, searchValue, setIsFocus }) => {
  const { data: companyData, loading: searchLoading, run: getSearchCompanyRun } = useRequest(getSearchCompany, {
    manual: true,
    ready: isFocus,
  });
  const { data: historyData, run: getSearchHistoryCompanyRun } = useRequest(getSearchHistoryCompany, {
    ready: isFocus,
  });

  const { run: addSearchHistoryCompanyRun } = useRequest(addSearchHistoryCompany, {
    manual: true,
    onSuccess: () => {
      getSearchHistoryCompanyRun();
    },
  });
  const { run: clearSearchHistoryCompanyRun } = useRequest(clearSearchHistoryCompany, {
    manual: true,
    onSuccess: () => {
      getSearchHistoryCompanyRun();
    },
  });
  useEffect(() => {
    getSearchCompanyRun(searchValue!);
  }, [getSearchCompanyRun, searchValue]);

  const hasSearchData = Boolean(companyData?.length);

  return (
    <div className={SearchCompanyStyle}>
      {/* Loading */}
      {searchLoading && searchValue && !hasSearchData && <div className="loading">Loading...</div>}
      {/* 没有搜索到数据 */}
      {searchValue && !hasSearchData && !searchLoading && (
        <NoData img={`${resourceCDNHost}/images/no-data-8601b8.svg`} className={styleNoData} />
      )}
      {hasSearchData ? (
        <div className="company-list">
          {companyData!.map(company => {
            return (
              <a
                key={company.uniScId}
                target="_blank"
                href={`/gvi${ROUTER_COMPANY_DETAIL_PREFIX}/${company.uniScId}`}
                rel="noreferrer"
                className="company-name"
                onClick={() => {
                  setIsFocus?.(false);
                  addSearchHistoryCompanyRun(company.uniScId);
                }}
              >
                {company.entityName}
              </a>
            );
          })}
        </div>
      ) : (
        <>
          {!searchValue && (
            <RecentSearch clearSearchHistoryCompanyRun={clearSearchHistoryCompanyRun} historyData={historyData || []} />
          )}
        </>
      )}
    </div>
  );
};

export default memo(SearchCompany);

const SearchCompanyStyle = css`
  color: #b3c6d8;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  .loading {
    padding: 24px 16px;
  }

  .company-list {
    padding: 4px;
  }

  .company-name {
    padding: 0 12px;
    color: #e7e7e7;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px; /* 137.5% */
    text-decoration: none;
    ${styleEllipsisLine(1)};
    &:hover {
      background-color: #2d3446;
    }
  }
`;

const styleNoData = css`
  height: 120px;
  margin-top: 0px;
  row-gap: 0px;
  .no-data-icon {
    width: 64px;
  }
  .no-data-tips {
    color: #b3c6d8;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
`;
