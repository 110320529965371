import { createModel } from '@rematch/core';
import { IRemainingTaskQuotaResponse } from 'services/user-role/types';
import { RootModel } from '../index';

export const taskQuotaInfo = createModel<RootModel>()({
  state: {} as IRemainingTaskQuotaResponse,
  reducers: {
    updateTaskQuotaInfo(state, payload: IRemainingTaskQuotaResponse) {
      let dealWithState = {
        ...state,
        ...payload,
      };

      return dealWithState;
    },
  },
  effects: dispatch => ({}),
});
