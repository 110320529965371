import { EGreenLevel, EGreenModel } from 'types/green.type';

export interface IMapStatisticListRequest {
  greenModel: EGreenModel;
  areaCode: string;
}

export interface IMapStatisticItem {
  provinceCode: string;
  cityCode: string;
  deepGreenCount: number;
  midGreenCount: number;
  lightGreenCount: number;
  maybeGreenCount: number;
  totalCount: number;
  provinceName: string;
  cityName: string;
}
export enum ERemainRuleType {
  EXPORT = 'A0001',
  UPLOAD = 'A0002',
}
export interface IQuotaRemainItem {
  ruleType: ERemainRuleType;
  threshold: number;
  remain: number;
}

export interface ISettingsInfo {
  greenLevelMapping: Partial<Record<EGreenLevel, string>>;
}
