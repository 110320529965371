import { ChangeEvent, memo, useCallback, useMemo, useRef, useState } from 'react';
import { css, cx } from '@emotion/css';
import { Input, InputRef, SearchOutlinedIcon, ThemeProvider } from '@miotech/prism';
import usePreventScroll from 'hooks/usePreventScroll';
import { debounce } from 'lodash';
import SearchCompany from 'components/search-company';
const LayoutSearchCompany = () => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  usePreventScroll(open);
  const suffixRender = useMemo(() => {
    return (
      <span className="search-icon">
        <SearchOutlinedIcon fill="secondary" />
      </span>
    );
  }, []);

  const debouncedChangeHandler = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchValue(value);
    }, 400);
  }, []);

  return (
    <div className={LayoutSearchCompanyStyle}>
      <ThemeProvider
        value={{
          system: 'dark',
          branding: 'brandingEsghub',
        }}
      >
        <Input
          value={searchValue}
          onFocus={() => {
            setOpen(true);
            setTimeout(() => {
              inputRef?.current?.focus();
            }, 0);
          }}
          suffix={suffixRender}
          className={inputStyle}
          placeholder="搜索公司名/统一社会信用代码"
        />
        <div className={popupStyle(open)} onClick={onClose} />
        <div className={inputSearchWrapperStyle(open)}>
          <Input
            size="large"
            onFocus={() => {
              setOpen(true);
            }}
            onChange={debouncedChangeHandler}
            ref={inputRef}
            clearable
            suffix={suffixRender}
            fullWidth
            className={cx(inputStyle, {
              'search-large': open,
            })}
            placeholder="搜索公司名/统一社会信用代码"
          />

          <SearchCompany isFocus={open} searchValue={searchValue} />
        </div>
      </ThemeProvider>
    </div>
  );
};

export default memo(LayoutSearchCompany);

const LayoutSearchCompanyStyle = css`
  margin-left: 12px;
`;

const inputSearchWrapperStyle = (popupVisible: boolean) => css`
  transition: all 0.3s;
  transform-origin: center;
  top: 0px;
  left: 210px;
  right: 0px;
  width: auto;
  position: fixed;
  opacity: ${popupVisible ? 1 : 0};
  width: ${popupVisible ? '1000px' : '0px'};
  padding: 12px 24px 0 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 2px;
  border: 1px solid #2d3446;
  background: #171e2d;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: ${popupVisible ? 15 : -1};
  overflow-x: hidden;
`;

const inputStyle = css`
  width: 248px;
  .search-icon {
    display: grid;
    place-items: center;
    height: 16px;
    padding-left: 12px;
    border-left: 1px solid #526079;
  }
  input::placeholder {
    color: #b3c6d8 !important;
  }
`;

export const popupStyle = (popupVisible: boolean) => css`
  display: ${popupVisible ? 'block' : 'none'};
  position: fixed;
  inset: 0px;
  background-color: rgba(18, 25, 39, 0.6);
  z-index: 14;
`;
