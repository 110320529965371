import { createModel } from '@rematch/core';
import { INotificationBaseInfoResponse } from 'services/notification/types';
import { RootModel } from '../index';

export const notificationInfo = createModel<RootModel>()({
  state: {} as INotificationBaseInfoResponse,
  reducers: {
    updateNotificationBaseInfo(state, payload: INotificationBaseInfoResponse) {
      let dealWithState = {
        ...state,
        ...payload,
      };

      return dealWithState;
    },
  },
  effects: dispatch => ({}),
});
