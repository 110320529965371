import { createModel } from '@rematch/core';
import { ISSOConfig } from 'services/auth/types';
import { RootModel } from '../index';

const defualtLogo = 'esghub-logo-blghkg.svg';
const defaultLogoBlack = 'user-esghub-logo-f3497f.svg';

export const initializeConfig = createModel<RootModel>()({
  state: {
    isSSOEnable: false,
    loginMessage: '',
    loginImage: '',
    esghubLogo: defualtLogo,
    esghubLogoBlack: defaultLogoBlack,
  },
  reducers: {
    updateisSSOEnable(state, payload: ISSOConfig) {
      if (!payload.esghubLogo) {
        payload.esghubLogo = defualtLogo;
      }
      if (!payload.esghubLogoBlack) {
        payload.esghubLogoBlack = defaultLogoBlack;
      }
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: dispatch => ({}),
});
