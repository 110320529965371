export const ROUTER_HOME = '/';
export const ROUTER_SCREENING = '/screening';
export const ROUTER_WATCHLIST = '/watchlist';
export const ROUTER_WATCHLIST_DETAIL = `${ROUTER_WATCHLIST}/details`;

export const ROUTER_COMPANY_DETAIL_PREFIX = '/company';
export const ROUTER_COMPANY_DETAIL = `${ROUTER_COMPANY_DETAIL_PREFIX}/:id`;

export const ROUTER_IDENTIFY = '/loan-labeling';

//ESG风险管理 
export const ROUTER_QUESTIONAIRE = '/esg-risk-dd/questionaire-list';
export const ROUTER_QUESTIONAIRE_DETAIL = '/esg-risk-dd/questionaire/detail/:id';
export const ROUTER_QUESTIONAIRE_SURVEY = '/esg-risk-dd/survey';
export const ROUTER_IDENTIFY_CREATE = '/identify-project-create';
export const ROUTER_IDENTIFY_PROJECT = '/project-detail';
export const ROUTER_IDENTIFY_PROJECT_DETAIL = `${ROUTER_IDENTIFY_PROJECT}/:id`;
export const ROUTER_IDENTIFY_PROJECT_CALC_PREFIX = '/project-calc';
export const ROUTER_IDENTIFY_PROJECT_CALC = `${ROUTER_IDENTIFY_PROJECT_CALC_PREFIX}/:id`;

export const ROUTER_IDENTIFY_FUND_CREATE = '/identify-fund-create';
export const ROUTER_IDENTIFY_FUND_DETAIL_PREFIX = '/identify-fund-detail';
export const ROUTER_IDENTIFY_FUND_DETAIL = `${ROUTER_IDENTIFY_FUND_DETAIL_PREFIX}/:id`;
export const ROUTER_IDENTIFY_FUND_CALC_PREFIX = '/fund-calc';
export const ROUTER_IDENTIFY_FUND_CALC = `${ROUTER_IDENTIFY_FUND_CALC_PREFIX}/:id`;
