import { createModel } from '@rematch/core';
import { RootModel } from '../index';

export const linkCheck = createModel<RootModel>()({
  state: { isValid: true },
  reducers: {
    updateLinkCheck(state, payload: boolean) {
      let dealWithState = {
        ...state,
        isValid: payload,
      };

      return dealWithState;
    },
  },
  effects: dispatch => ({}),
});
