import { createModel } from '@rematch/core';
import { getSettingsInfoAction } from 'services/home/home.service';
import { EGreenLevel, greenLevelBgColorMap, greenLevelOrder, greenLevelTextColorMap } from 'types/green.type';
import { RootModel } from '..';

export interface IGreenLevelOption {
  label: string;
  value: EGreenLevel;
}

export interface IGreenLevelMapItem extends IGreenLevelOption {
  color: string;
  bgColor: string;
}
export type TIGreenLevelMap = Record<EGreenLevel, IGreenLevelMapItem>;

export interface IState {
  greenLevelList: IGreenLevelOption[];
  greenLevelListMap: TIGreenLevelMap;
}

export const greenLevel = createModel<RootModel>()({
  state: ({
    greenLevelList: [],
    greenLevelListMap: {},
  } as unknown) as IState,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: dispatch => ({
    async updateAsync() {
      const options: IGreenLevelOption[] = [];
      const optionsMap: TIGreenLevelMap = {} as TIGreenLevelMap;
      const settingsInfoRes = await getSettingsInfoAction();
      const greenLevelMapping = settingsInfoRes?.result?.greenLevelMapping || {};

      greenLevelOrder.forEach(greenLevel => {
        if (greenLevelMapping[greenLevel]) {
          options.push({
            label: greenLevelMapping[greenLevel]!,
            value: greenLevel,
          });
        }
        optionsMap[greenLevel] = {
          bgColor: greenLevelBgColorMap[greenLevel],
          color: greenLevelTextColorMap[greenLevel],
          label: greenLevelMapping[greenLevel]!,
          value: greenLevel,
        };
      });
      dispatch.greenLevel.update({
        greenLevelList: options,
        greenLevelListMap: optionsMap,
      });
    },
  }),
});
