import { GVI_ENDPOINT } from 'constants/endpoint';
import { connector, urlBuilder } from '../base';
import { ISearchCompany } from './type';

let build = urlBuilder(GVI_ENDPOINT);

export async function getSearchCompany(keyword: string) {
  const res = await connector.post<ISearchCompany[]>({
    url: build(`/search/companyBriefInfo`),
    data: {
      keyword,
    },
  });
  return res.result;
}

export async function getSearchHistoryCompany() {
  const res = await connector.post<ISearchCompany[]>({
    url: build(`/search/history`),
  });
  return res.result;
}

export async function clearSearchHistoryCompany() {
  const res = await connector.post<any>({
    url: build(`/search/clear`),
  });
  return res.result;
}

export async function addSearchHistoryCompany(uniScId: string) {
  const res = await connector.post<any>({
    url: build(`/search/record`),
    data: {
      uniScId,
    },
  });
  return res.result;
}
