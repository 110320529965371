import { createModel } from '@rematch/core';
import { EUserLanguage, IInternalWhoAmiResponse, IWhoAmIResponse } from 'services/user-role/types';
import { RootModel } from '../index';

export interface IUserInfo extends Partial<IWhoAmIResponse> {
  internalWhoAmi: IInternalWhoAmiResponse;
  languageCache: EUserLanguage;
}

export const userInfo = createModel<RootModel>()({
  state: {} as IUserInfo,
  reducers: {
    updateUserInfo(state, payload: IWhoAmIResponse) {
      let dealWithState = {
        ...state,
        languageCache: payload.preference.language,
        ...payload,
      };

      return dealWithState;
    },
    updateInternalWhoAmi(state, payload: IInternalWhoAmiResponse) {
      let dealWithState = {
        ...state,
        internalWhoAmi: payload,
      };
      localStorage.setItem(
        'wmUserInfo',
        JSON.stringify({ userId: payload.companyId, userTag: payload.companyName, projectVersion: '2022-05-29' }),
      );

      return dealWithState;
    },
    updateLanguageCache(state, payload: EUserLanguage) {
      let dealWithState = {
        ...state,
        languageCache: payload,
      };
      return dealWithState;
    },
  },
  effects: dispatch => ({}),
});
