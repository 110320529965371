import { createModel } from '@rematch/core';
import { IIndicatorRow } from 'services/indicators/types';
import { IRatingIndicator } from 'services/rating/type';
import { RootModel } from '../index';

interface IState {
  originIndicatorList: IIndicatorRow[];
  ratingIndicatorList: IRatingIndicator[];
}

export const indicatorInfo = createModel<RootModel>()({
  state: {} as IState,
  reducers: {
    updateOriginIndicatorList(state, payload: IState['originIndicatorList']) {
      let dealWithState = {
        ...state,
        originIndicatorList: payload,
      };

      return dealWithState;
    },
    updateRatingIndicatorList(state, payload: IState['ratingIndicatorList']) {
      let dealWithState = {
        ...state,
        ratingIndicatorList: payload,
      };

      return dealWithState;
    },
  },
  effects: dispatch => ({}),
});
