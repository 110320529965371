import { css } from '@emotion/css';
import { Popover } from 'antd';
import { memo, useMemo } from 'react';
import { get } from 'lodash';
import { Analytics, redirectToApplication } from '@miotech/shared';

const UserCenter = () => {
  const basicInfo = get(window, '__user__.basicInfo') || {};

  const getFirstLetter = (str: string) => {
    return str.slice(0, 1).toLocaleUpperCase();
  };

  const contentRender = useMemo(() => {
    return (
      <div className={styleContentRender}>
        <div className="user-name">
          <div>
            {basicInfo.firstName} {basicInfo.lastName}
          </div>
          <div className="email">{basicInfo.email}</div>
        </div>
        <div className="line"></div>
        <div
          className="login-out"
          onClick={() => {
            Analytics.post('/api/user/logout').then(response => {
              if (response.code === 0 || response.code === 27) {
                localStorage.clear();
                sessionStorage.clear();
                redirectToApplication('USER', '');
              }
            });
          }}
        >
          退出登录
        </div>
      </div>
    );
  }, [basicInfo.email, basicInfo.firstName, basicInfo.lastName]);

  return (
    <Popover
      arrow={false}
      align={{
        offset: [0, 5],
      }}
      rootClassName={styleRootPopover}
      placement="bottomRight"
      content={contentRender}
    >
      <div className={styleUserCenter}>
        {getFirstLetter(basicInfo.firstName || '')}
        {getFirstLetter(basicInfo.lastName || '')}
      </div>
    </Popover>
  );
};

export default memo(UserCenter);

const styleUserCenter = css`
  display: grid;
  place-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(6, 194, 127, 0.5);
  color: #e7e7e7;

  font-size: 14px;
  line-height: 16px;
  margin-left: 17px;
  cursor: pointer;
`;
const styleRootPopover = css`
  z-index: 999;
  .ant-popover-inner {
    border-radius: 2px;
    border: 1px solid #2d3446;
    background: #171e2d;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    padding: 0;
  }
`;
const styleContentRender = css`
  .user-name {
    color: #e7e7e7;
    font-size: 14px;
    line-height: 22px; /* 157.143% */
    padding: 12px 16px;

    .email {
      color: #b3c6d8;
      margin-top: 4px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #2d3446;
  }

  .login-out {
    padding: 12px 16px;
    color: #ff6336;
    font-size: 12px;
    line-height: 14px; /* 116.667% */
    cursor: pointer;
  }
`;
