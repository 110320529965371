import { miotechHost, resourceCDNHost } from 'constants/cdn';
import { ROUTER_HOME } from 'constants/router';
import { HIDE_MIO_LOGO_ORGS } from 'constants/user';
import { css, cx } from '@emotion/css';
import { flex1 } from 'styles/template-style';
import LayoutSearchCompany from 'layouts/main/layout-search-company';
import { FC, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import FeatureList from './feature-list';
import TrialQuota from './trial-quota';
import UserCenter from './user-center';

const MainLayout: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { pathname } = useLocation();

  const basicInfo = get(window, '__user__.basicInfo') || {};

  const hideLogo = useMemo(() => basicInfo.organization && HIDE_MIO_LOGO_ORGS.includes(basicInfo.organization), [
    basicInfo.organization,
  ]);

  return (
    <div className={styleMainLayout}>
      <div className={styleNav}>
        {!hideLogo && (
          <a href={miotechHost}>
            <img width={50} src={`${resourceCDNHost}/images/miotech-ad119c.svg`} alt="miotech-logo" />
          </a>
        )}

        <Link
          to={ROUTER_HOME}
          className={cx('gvi-logo', {
            active: pathname === ROUTER_HOME,
          })}
        >
          <img width={58} src={`${resourceCDNHost}/images/gvi-cebb8c.svg`} alt="gvi-logo" />
        </Link>

        <FeatureList />
        {pathname !== ROUTER_HOME && <LayoutSearchCompany />}

        <div className={flex1} />
        <TrialQuota />
        <UserCenter />
      </div>
      {children}
    </div>
  );
};
const styleMainLayout = css`
  padding-top: 48px;
`;
const styleNav = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  background: #121927;
  border-bottom: 1px solid #2d3446;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  z-index: 16;
  .gvi-logo {
    padding: 4px 12px;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 24px 0 32px;
    &.active {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  img {
    vertical-align: middle;
  }
`;

export default MainLayout;
