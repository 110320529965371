import { resourceCDNHost } from 'constants/cdn';
import { css, cx } from '@emotion/css';
import React, { memo, FC } from 'react';

const NoData: FC<{
  text?: string;
  img?: string;
  className?: string;
  style?: React.CSSProperties;
}> = ({ text, img, className, style }) => {
  return (
    <div style={style} className={cx(noDataStyle, className)}>
      <img className="no-data-icon" src={img || `${resourceCDNHost}/images/no-data-864f39.png`} alt="icon" />
      <div className="no-data-tips">{text || '无符合条件的记录'}</div>
    </div>
  );
};

const noDataStyle = css`
  height: 300px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;

  .no-data-icon {
    width: 165px;
  }
  .no-data-tips {
    color: #959a9f;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
`;
export default memo(NoData);
