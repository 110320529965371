import { createModel } from '@rematch/core';
import { IBasicInfo } from 'pages/survey/BasicInfo/utils';
import { RootModel } from '../index';

export const questionnaireInfo = createModel<RootModel>()({
  state: {} as IBasicInfo,
  reducers: {
    updateQuestionnaire(state, payload: IBasicInfo) {
      let dealWithState = {
        ...payload,
      };

      return dealWithState;
    },
  },
  effects: dispatch => ({}),
});
