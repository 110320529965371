import {
  ROUTER_SCREENING,
  ROUTER_HOME,
  ROUTER_COMPANY_DETAIL_PREFIX,
  ROUTER_COMPANY_DETAIL,
  ROUTER_WATCHLIST,
  ROUTER_WATCHLIST_DETAIL,
  ROUTER_IDENTIFY,
  ROUTER_IDENTIFY_CREATE,
  ROUTER_IDENTIFY_PROJECT,
  ROUTER_IDENTIFY_PROJECT_DETAIL,
  ROUTER_QUESTIONAIRE,
  ROUTER_IDENTIFY_PROJECT_CALC_PREFIX,
  ROUTER_IDENTIFY_PROJECT_CALC,
  ROUTER_IDENTIFY_FUND_CREATE,
  ROUTER_IDENTIFY_FUND_DETAIL_PREFIX,
  ROUTER_IDENTIFY_FUND_DETAIL,
  ROUTER_QUESTIONAIRE_DETAIL,
  ROUTER_QUESTIONAIRE_SURVEY,
  ROUTER_IDENTIFY_FUND_CALC_PREFIX,
  ROUTER_IDENTIFY_FUND_CALC,
} from 'constants/router';
import React, { lazy, Suspense, useLayoutEffect, useMemo } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { SentryRoute as Route, Spin } from '@miotech/shared';
import MainLayout from 'layouts/main';
import useRedux from 'hooks/useRedux';
const HomePage = lazy(() => import('./pages/home'));
const SurveyPage = lazy(() => import('./pages/survey/Survey'));
const QuestionaireList = lazy(() => import('./pages/questionaire/list'));
const QuestionaireDetail = lazy(() => import('./pages/questionaire/detail/index'));

const ScreeningPage = lazy(() => import('./pages/screening'));
const CompanyDetailPage = lazy(() => import('./pages/company-detail'));

const GhostPDF = lazy(() => import('./pages/pdf'));
const WatchList = lazy(() => import('./pages/watch/list'));
const WatchDetails = lazy(() => import('./pages/watch/details'));

const IdentifyPage = lazy(() => import('./pages/identify'));

// ESG风险管理
const IdentifyCreatePage = lazy(() => import('./pages/identify-project-create'));
const IdentifyProjectDetailPage = lazy(() => import('./pages/identify-project-detail'));
const IdentifyProjectCalcPage = lazy(() => import('./pages/identify-project-calc'));
const IdentifyFundCreatePage = lazy(() => import('./pages/identify-fund-create'));
const IdentifyFundDetailPage = lazy(() => import('./pages/identify-fund-detail'));
const IdentifyFundCalcPage = lazy(() => import('./pages/identify-fund-calc'));

interface LocationState {
  background: Location;
}

const ESGRISKDD = '/esg-risk-dd';

const mainLayoutRouters = [
  ROUTER_HOME,
  ROUTER_SCREENING,
  ROUTER_WATCHLIST,
  ROUTER_WATCHLIST_DETAIL,
  ROUTER_COMPANY_DETAIL_PREFIX,
  ROUTER_IDENTIFY,
  ROUTER_IDENTIFY_CREATE,
  ROUTER_IDENTIFY_PROJECT,
  ESGRISKDD,
  ROUTER_QUESTIONAIRE,
  ROUTER_QUESTIONAIRE_DETAIL,
  ROUTER_QUESTIONAIRE_SURVEY,
  ROUTER_IDENTIFY_PROJECT_CALC_PREFIX,
  ROUTER_IDENTIFY_FUND_CREATE,
  ROUTER_IDENTIFY_FUND_DETAIL_PREFIX,
  ROUTER_IDENTIFY_FUND_DETAIL_PREFIX,
  ROUTER_IDENTIFY_FUND_CALC_PREFIX,
];

const Container: React.FC = () => {
  const location = useLocation<LocationState>();
  const { dispatch } = useRedux(s => s.greenLevel);

  useLayoutEffect(() => {
    dispatch.quotaRemain.updateAsync();
    dispatch.greenLevel.updateAsync();
  }, [dispatch.greenLevel, dispatch.quotaRemain]);

  const memoRoute = useMemo(() => {
    return (
      <div>
        <Suspense
          fallback={
            <Spin>
              <div style={{ height: '100px' }}></div>
            </Spin>
          }
        >
          <Switch location={location}>
            <Route
              path={`(${mainLayoutRouters.join('|')})`}
              component={() => {
                return (
                  <MainLayout>
                    <Switch>
                      <Suspense
                        fallback={
                          <Spin>
                            <div style={{ height: '100px' }}></div>
                          </Spin>
                        }
                      >
                        <Route path={ROUTER_HOME} exact component={HomePage} />
                        <Route path={ROUTER_SCREENING} exact component={ScreeningPage} />
                        <Route path={ROUTER_COMPANY_DETAIL} component={CompanyDetailPage} />
                        <Route path={ROUTER_WATCHLIST} exact component={WatchList} />
                        <Route path={ROUTER_WATCHLIST_DETAIL} exact component={WatchDetails} />
                        <Route path={ROUTER_IDENTIFY} exact component={IdentifyPage} />
                        <Route path={ROUTER_IDENTIFY_CREATE} exact component={IdentifyCreatePage} />
                        <Route path={ROUTER_IDENTIFY_PROJECT_DETAIL} component={IdentifyProjectDetailPage} />
                        <Route path={ROUTER_QUESTIONAIRE} exact component={QuestionaireList} />
                        <Route path={ROUTER_QUESTIONAIRE_DETAIL} component={QuestionaireDetail} />
                        <Route path={ROUTER_QUESTIONAIRE_SURVEY} component={SurveyPage} />
                        <Route path={ROUTER_IDENTIFY_PROJECT_CALC} component={IdentifyProjectCalcPage} />
                        <Route path={ROUTER_IDENTIFY_FUND_CREATE} exact component={IdentifyFundCreatePage} />
                        <Route path={ROUTER_IDENTIFY_FUND_DETAIL} exact component={IdentifyFundDetailPage} />
                        <Route path={ROUTER_IDENTIFY_FUND_CALC} exact component={IdentifyFundCalcPage} />
                      </Suspense>
                    </Switch>
                  </MainLayout>
                );
              }}
            />

            {/* <Redirect path="/esg-risk-dd" exact to="/esg-risk-dd/questionaire/list" /> */}
            {/* <Route path="/esg-risk-dd/survey" component={SurveyPage} />
        <Route path="/esg-risk-dd/questionaire/list" component={QuestionaireList} />
        <Route path="/esg-risk-dd/questionaire/detail/:id" component={QuestionaireDetail} /> */}
            <Route path="/ghost-pdf/:id" component={GhostPDF} />
          </Switch>
        </Suspense>
      </div>
    );
  }, [location]);

  return <>{memoRoute}</>;
};

export default Container;
