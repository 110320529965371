import { createModel } from '@rematch/core';
import { RatingInfoResponse } from 'services/rating/type';
import { RootModel } from '../index';

export const ratingReportInfo = createModel<RootModel>()({
  state: {} as RatingInfoResponse,
  reducers: {
    updateRatingReportInfo(state, payload: string) {
      let dealWithState = {
        ...state,
        ratingReportId: payload,
      };

      return dealWithState;
    },
    updateIsRatingShow(state, payload: boolean) {
      let dealWithState = {
        ...state,
        isRatingShow: payload,
      };
      return dealWithState;
    },
    updateRequestNumber(state, payload: number) {
      let dealWithState = {
        ...state,
        requestNumber: payload,
      };
      return dealWithState;
    },
  },
  effects: dispatch => ({}),
});
