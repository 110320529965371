import { ROUTER_SCREENING, ROUTER_WATCHLIST,ROUTER_IDENTIFY,ROUTER_QUESTIONAIRE } from 'constants/router';
import { css, cx } from '@emotion/css';
import {
  DetailOutlinedIcon,
  EnergyOutlinedIcon,
  LeafOutlinedIcon,
  ListOutlinedIcon,
  ReportOutlinedIcon,
  TransformOutlinedIcon,
} from '@miotech/prism';
import { Dropdown } from 'antd';
import { memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const features = [
  {
    label: '绿色金融主动拓客',
    path: ROUTER_SCREENING,
    icon: <LeafOutlinedIcon />,
  },
  {
    label: '绿色信贷自动识别与打标',
    path: ROUTER_IDENTIFY,
    icon: <DetailOutlinedIcon />,
  },
  {
    label: 'ESG风险管理',
    path: ROUTER_QUESTIONAIRE,
    icon: <EnergyOutlinedIcon />,
    disabled: false,
  },
  {
    label: '绿色信贷监管报送',
    path: '4',
    icon: <TransformOutlinedIcon />,
    disabled: true,
  },
  {
    label: '客户名单管理',
    path: ROUTER_WATCHLIST,
    icon: <ReportOutlinedIcon />,
  },
];

const FeatureList = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const dropdownRender = () => {
    return (
      <ul className={styleFeatureList}>
        {features.map((featureItem, index) => {
          return (
            <li
              onClick={() => {
                if (featureItem.disabled) {
                  return;
                }
                history.push(featureItem.path);
              }}
              key={`${featureItem.path}-${index}`}
              className={cx({
                disabled: featureItem.disabled,
                active: pathname === featureItem.path,
              })}
            >
              {featureItem.icon}
              {featureItem.label}
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <Dropdown
      menu={{ items: [] }}
      overlayClassName={overlayClassName}
      dropdownRender={dropdownRender}
      align={{
        offset: [0, 0],
      }}
    >
      <div className={styleFeatureListBtn}>
        <ListOutlinedIcon />
        功能列表
      </div>
    </Dropdown>
  );
};

export default memo(FeatureList);

const styleFeatureListBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: 100%;
  padding: 8px 16px;
  background: rgba(6, 194, 127, 0.8);
  color: #e7e7e7;
  font-size: 14px;
  cursor: pointer;
`;
const overlayClassName = css`
  background: #121927;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
`;

const styleFeatureList = css`
  padding: 8px 0;
  color: #e7e7e7;

  font-size: 12px;
  line-height: 20px; /* 166.667% */
  li {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 8px 16px;
    cursor: pointer;
    &.active {
      background: #2d3446;
    }
    &:hover {
      background: #2d3446;
    }
    &.disabled {
      color: #526079;
    }
  }
`;
