import { createModel } from '@rematch/core';
import { IPortfolioIndustryListResponse } from 'services/portfolio-company/types';
import { RootModel } from '../index';

export const portfolioInfo = createModel<RootModel>()({
  state: {
    portfolioIndustryMap: new Map<string, string>(),
  },
  reducers: {
    updatePortfolioIndustry(state, payload: IPortfolioIndustryListResponse[]) {
      const portfolioIndustryMap = new Map<string, string>();

      payload.forEach(item => {
        portfolioIndustryMap.set(item.industryCode, item.industryName);
      });

      return { ...state, portfolioIndustryMap };
    },
  },
  effects: dispatch => ({}),
});
