import { instance } from '@miotech/shared';
import { message } from 'antd';
import qs from 'qs';

export interface ResponseBody<T> {
  code: number;
  message: string;
  detailCode: string;
  result: T;
}

export function urlBuilder(baseUrl: string): (path?: string, query?: Record<string, any>) => string {
  return function build(path, query) {
    let url = baseUrl;
    if (path) {
      url += path;
    }
    if (query) {
      url +=
        '?' +
        Object.entries(query)
          .reduce((accu, entry) => (entry[1] === undefined ? accu : accu + `${entry[0]}=${entry[1]}&`), '')
          .slice(0, -1);
    }
    return url;
  };
}

instance.interceptors.response.use(
  response => {
    if (response.status === 403 || response?.data?.code === 26) {
      return Promise.reject('response.data.message || ‘没有权限’');
    }
    if (response.data && response.data.type) {
      return Promise.resolve(response);
    }
    if (response.data && response.data.code !== 0) {
      message.error(response.data.message);
      return Promise.reject(response.data.message || '数据结构异常');
    }
    return Promise.resolve(response);
  },
  error => {
    const errStr = String(error);
    if (errStr.indexOf('500') >= 0) {
      message.error('系统异常，请联系管理员');
      return Promise.reject();
    }
    if (errStr.indexOf('401') >= 0) {
      message.error('没有访问权限');
      return Promise.reject();
    }
    message.error('网络请求异常');
    return Promise.reject();
  },
);

export const connector = {
  get: async <T = any>(props: { url: string; data?: any }) => {
    const { url, data } = props;
    const res = await instance.get<ResponseBody<T>>(url, {
      params: data,
      method: 'get',
      paramsSerializer: params =>
        qs.stringify(params, {
          arrayFormat: 'repeat',
          skipNulls: true,
        }),
    });
    return res.data;
  },
  post: async <T = any>(props: { url: string; data?: any; config?: any }) => {
    const { url, data, config } = props;
    const res = await instance.post<ResponseBody<T>>(url, data, {
      ...config});
    return res.data;
  },
  put: async <T = any>(props: { url: string; data?: any }) => {
    const { url, data } = props;
    const res = await instance.put<ResponseBody<T>>(url, data);
    return res.data;
  },
  delete: async <T = any>(props: { url: string; data?: any }) => {
    const { url, data } = props;
    const res = await instance.delete<ResponseBody<T>>(url, { data });
    return res.data;
  },
};

export const propsWithLocale: <T>(props: T) => T & { locale: string } = props => ({
  ...props,
  locale: 'zh_CN',
});
