import { createModel } from '@rematch/core';
import { getQuotaRemainAction } from 'services/home/home.service';
import { ERemainRuleType, IQuotaRemainItem } from 'services/home/home.service.type';
import { keyBy } from 'lodash';
import { RootModel } from '..';

export type TQuotaRemainMapState = Record<ERemainRuleType, IQuotaRemainItem>;

export const quotaRemain = createModel<RootModel>()({
  state: {
    quotaRemainMap: {} as TQuotaRemainMapState,
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: dispatch => ({
    async updateAsync() {
      const remainRes = await getQuotaRemainAction();
      const remainList = remainRes.result || [];
      const quotaRemainMap = keyBy(remainList, 'ruleType') as TQuotaRemainMapState;
      dispatch.quotaRemain.update({
        quotaRemainMap,
      });
    },
  }),
});
