import { css } from '@emotion/css';
import { InformationOutlinedIcon } from '@miotech/prism';
import { Popover } from 'antd';
import useRedux from 'hooks/useRedux';
import { memo, useCallback, useMemo } from 'react';
import { ERemainRuleType, IQuotaRemainItem } from 'services/home/home.service.type';
import { cursorPointer } from 'styles/template-style';
import { strongMap } from 'utils/strong';

const TrialQuota = () => {
  const {
    selector: { quotaRemainMap },
  } = useRedux(s => s.quotaRemain);

  const getValue = useCallback(
    (mapKey: ERemainRuleType, prop: keyof IQuotaRemainItem) => {
      return strongMap(quotaRemainMap, mapKey)[prop] || 0;
    },
    [quotaRemainMap],
  );

  const contentRender = useMemo(() => {
    return (
      <div className={styleContentRender}>
        <div className="title">试用配额</div>
        {quotaRemainMap[ERemainRuleType.UPLOAD] && (
          <div>剩余可上传企业数量：{getValue(ERemainRuleType.UPLOAD, 'remain')}</div>
        )}
        {quotaRemainMap[ERemainRuleType.EXPORT] && (
          <div>剩余可导出企业数量：{getValue(ERemainRuleType.EXPORT, 'remain')}</div>
        )}
      </div>
    );
  }, [getValue, quotaRemainMap]);

  return (
    <div className={styleTrialQuota}>
      {quotaRemainMap[ERemainRuleType.UPLOAD] && <span>剩余可上传 {getValue(ERemainRuleType.UPLOAD, 'remain')}</span>}
      {quotaRemainMap[ERemainRuleType.EXPORT] && <span>可导出 {getValue(ERemainRuleType.EXPORT, 'remain')}</span>}

      <Popover
        arrow={false}
        align={{
          offset: [10, 0],
        }}
        rootClassName={styleRootPopover}
        placement="bottomRight"
        content={contentRender}
      >
        <InformationOutlinedIcon className={cursorPointer} fill="secondary" />
      </Popover>
    </div>
  );
};

export default memo(TrialQuota);

const styleTrialQuota = css`
  display: flex;
  padding: 6px 12px;
  align-items: center;

  color: #e7e7e7;
  font-size: 10px;
  font-weight: 400;

  gap: 8px;
  border-radius: 2px;
  padding: 6px 12px;
  background: #2d3446;
`;
const styleRootPopover = css`
  z-index: 999;
  .ant-popover-inner {
    border-radius: 2px;
    border: 1px solid #2d3446;
    background: #171e2d;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }
`;
const styleContentRender = css`
  color: #fff;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */

  .title {
    color: #b3c6d8;
    margin-bottom: 4px;
  }
`;
